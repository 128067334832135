import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import { useSession } from '../../UserProvider'
import { db } from '../../firebase/config'
import LessonsTable from '../lessons/LessonsTable'
import { doc, onSnapshot, addDoc, collection } from 'firebase/firestore'

const Student = () => {
  const [student, setStudent] = useState(null)
  const { id: urlId } = useParams()
  const navigate = useNavigate()
  const session = useSession()
  const { isAppAdmin, isCoach } = session
  const id = urlId || localStorage.getItem('studentId') || session.user.uid
  const [sessionStarted, setSessionStarted] = useState(
    localStorage.getItem('studentId') !== null
  )

  useEffect(() => {
    const ref = doc(db, 'users', id)
    const unsubscribe = onSnapshot(ref, (docSnap) => {
      if (docSnap.exists()) {
        setStudent({ id: docSnap.id, ...docSnap.data() })
      } else {
        setStudent(null)
      }
    })
    return () => unsubscribe()
  }, [id])

  const handleStartEndSession = () => {
    if (!sessionStarted) {
      setSessionStarted(true)
      localStorage.setItem('studentId', id)
      if (student) {
        localStorage.setItem(
          'studentName',
          `${student.firstName} ${student.lastName}`
        )
      }
    } else {
      setSessionStarted(false)
      localStorage.removeItem('studentId')
      localStorage.removeItem('studentName')
      localStorage.removeItem('studentVocalRange')
    }
  }

  const handleCreateNewLesson = async () => {
    if (!sessionStarted) {
      console.error('Cannot create a new lesson without an active session.')
      return
    }
    const currentDate = new Date().toLocaleDateString()
    try {
      const lessonRef = await addDoc(collection(db, 'workouts'), {
        userId: localStorage.getItem('studentId'),
        title: `New Workout - ${currentDate}`,
        createdAt: new Date(),
      })
      navigate(`/lesson-edit/${lessonRef.id}`)
    } catch (error) {
      console.error('Error creating new lesson:', error)
    }
  }

  const handleDone = () => {
    // Navigate to a specified route when "Done" is clicked
    handleStartEndSession()
    navigate('/students') // Adjust the route as needed
  }

  const handleCoachViewNavigation = () => {
    navigate(`/student-coach/${id}`) // Navigate to the coach view
  }

  if (!student) {
    return <Typography>Loading...</Typography>
  }

  return (
    <Box sx={{ p: 1, bgcolor: 'background.default', display: 'grid', gap: 2 }}>
      {(isAppAdmin || isCoach) && (
        <Box sx={{ mb: 2 }}>
          <Typography
            variant='h4'
            component='h2'
            sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            Student: {student.firstName} {student.lastName}{' '}
            {`(${student.name})`}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}>
            <Button
              variant='contained'
              onClick={handleCreateNewLesson}
              disabled={false}>
              New Workout
            </Button>
            <Button variant='contained' onClick={handleCoachViewNavigation}>
              Go to Coach View
            </Button>
            <Button variant='contained' onClick={handleDone}>
              Done
            </Button>
          </Box>
        </Box>
      )}
      <LessonsTable studentId={id} />
    </Box>
  )
}

export default Student
