import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Paper, Typography } from '@mui/material'
import WorkoutModal from './WorkoutModal'
import { fetchAllSlices } from '../../firebase/dataHelper'
import { db } from '../../firebase/config'
import { collection, addDoc, writeBatch, doc } from 'firebase/firestore'
import { useSession } from '../../UserProvider'
import {
  getExercisesByTag,
  getExercisesByLabel,
  getWarmupExerciseCount,
} from './WorkoutHelpers'

function WorkoutGen() {
  const [focus, setFocus] = useState('Breath')
  const [time, setTime] = useState(15)
  const [intensity, setIntensity] = useState(5)
  const [showCustomization, setShowCustomization] = useState(true)
  const [labels, setLabels] = useState([])
  const navigate = useNavigate()
  const { user } = useSession()

  useEffect(() => {
    const loadSlices = async () => {
      try {
        const slices = await fetchAllSlices()
        const uniqueLabels = new Set(slices.flatMap((slice) => slice.labels))
        setLabels([...uniqueLabels])
        console.log('Slices loaded:', slices)
      } catch (error) {
        console.error('Failed to load slices:', error)
      }
    }
    loadSlices()
  }, [])

  const generateWorkout = async () => {
    console.log('Workout minutes:', time)
    try {
      const allSlices = await fetchAllSlices()

      const warmupExerciseCount = getWarmupExerciseCount(time)

      let warmupSlices = getExercisesByTag(
        'Warmup',
        allSlices,
        warmupExerciseCount
      )
      
      const mainCount = time - warmupExerciseCount
      let mainSlices = getExercisesByLabel(focus, allSlices, mainCount)

      let cooldownExercises = getExercisesByTag(
        'Warmup',
        allSlices,
        1
      )

      let workoutSequence = []
      workoutSequence.push(...warmupSlices)
      workoutSequence.push(...mainSlices)
      workoutSequence.push(...cooldownExercises)

      setShowCustomization(false)

      const date = new Date()
      const formattedDate = date.toLocaleDateString('en-US', {
        weekday: 'long', // e.g., Monday
        year: 'numeric', // e.g., 2021
        month: 'long', // e.g., July
        day: 'numeric', // e.g., 31
      })
      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      })

      const lessonData = {
        title: `${focus} Workout`,
        createdAt: date, // Keeping original Date object here for potential backend use
        userId: user.uid, // Ensure user ID is correctly retrieved
        notes: `Created on ${formattedDate} at ${formattedTime}. This workout session is designed to focus on ${focus} for ${time} minutes.`,
      }


      const lessonRef = await addDoc(collection(db, 'workouts'), lessonData)

      const batch = writeBatch(db)
      workoutSequence.forEach((slice, index) => {
        const { id, ...restOfSlice } = slice // Destructure 'id' from the slice
        const sliceData = {
          ...restOfSlice, // Include the rest of the slice's properties
          soundSliceId_Ext: id, // Rename 'id' to 'soundSliceId_Ext'
          order: index, // Set the order within the workout
        }
        const sliceRef = doc(collection(db, `workouts/${lessonRef.id}/slices`))
        batch.set(sliceRef, sliceData)
      })

      await batch.commit()
      navigate(`/lesson/${lessonRef.id}`)
    } catch (error) {
      console.error('Failed to generate workout:', error)
    }
  }

  return (
    <Box sx={{ padding: 2 }}>
      {showCustomization ? (
        <WorkoutModal
          focus={focus}
          setFocus={setFocus}
          time={time}
          setTime={setTime}
          intensity={intensity}
          setIntensity={setIntensity}
          onGenerateWorkout={generateWorkout}
          labels={labels}
        />
      ) : (
        <Paper elevation={6} sx={{ width: '100%', mt: 2 }}>
          <Typography sx={{ p: 2 }} variant='h5' component='h3'>
            Your Workout
          </Typography>
          {/* Render selected slices here */}
        </Paper>
      )}
    </Box>
  )
}

export default WorkoutGen
