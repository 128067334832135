import React, { useState, useEffect } from 'react'
import { db } from '../../firebase/config'
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore'
import { Modal, Box, Typography, TextField, Button } from '@mui/material'
import { toast } from 'react-toastify'

function CustomTextModal({
  open,
  onClose,
  lessonId,
  onTextAdded,
  maxOrder,
  editableText,
}) {
  const [name, setName] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    if (editableText) {
      setName(editableText.name || '')
      setText(editableText.text || '')
    }
  }, [editableText])

  const handleAddOrUpdateText = async () => {
    if (!name.trim() || !text.trim()) {
      toast.error('Please provide both a name and text.')
      return
    }

    const textData = {
      createdAt: new Date(),
      name,
      text,
      order: editableText ? editableText.order : maxOrder + 1,
      type: 'text', // Type is set to differentiate text content
    }

    try {
      const textRef = editableText
        ? doc(db, `workouts/${lessonId}/slices`, editableText.id)
        : collection(db, `workouts/${lessonId}/slices`)
      const action = editableText
        ? updateDoc(textRef, textData)
        : addDoc(textRef, textData)
      await action
      onClose(true)
      onTextAdded()
      toast.success(
        editableText ? 'Text updated successfully!' : 'Text added successfully!'
      )
      // Reset name and text after successfully adding or updating a text
      if (!editableText) {
        setName('')
        setText('')
      }
    } catch (error) {
      toast.error('Error updating text in lesson.')
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby='modal-title'>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}>
        <Typography id='modal-title' variant='h6' sx={{ mb: 2 }}>
          {editableText ? 'Edit Text in Workout' : 'Add Text to Workout'}
        </Typography>
        <TextField
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin='normal'
          fullWidth
        />
        <TextField
          label='Text'
          value={text}
          onChange={(e) => setText(e.target.value)}
          margin='normal'
          fullWidth
          multiline
          rows={4}
        />
        <Button
          variant='contained'
          onClick={handleAddOrUpdateText}
          sx={{ mt: 2 }}>
          {editableText ? 'Update Text' : 'Add Text'}
        </Button>
      </Box>
    </Modal>
  )
}

export default CustomTextModal
