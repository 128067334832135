import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { db } from '../../firebase/config'
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  orderBy
} from 'firebase/firestore'
import { useSession } from '../../UserProvider'

const formatDate = (date) => {
  return date?.toDate().toLocaleDateString()
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}))

const LessonsTable = ({ studentId }) => {
  const [lessons, setLessons] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openDialog, setOpenDialog] = useState(false)
  const [lessonToDelete, setLessonToDelete] = useState(null)
  const { isAppAdmin } = useSession()
  const navigate = useNavigate()

// TEMP - index was building...
useEffect(() => {
  const fetchLessons = async () => {
    const lessonsQuery = query(
      collection(db, 'workouts'),
      where('userId', '==', studentId)
    )
    const snap = await getDocs(lessonsQuery)
    const lessons = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

    // Sort lessons by createdAt in descending order
    lessons.sort((a, b) => {
      return b.createdAt.seconds - a.createdAt.seconds // Reverse the order by swapping a and b
    })

    setLessons(lessons)
  }

  fetchLessons()
}, [studentId])




  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDelete = async () => {
    await deleteDoc(doc(db, 'workouts', lessonToDelete))
    setLessons(lessons.filter((lesson) => lesson.id !== lessonToDelete))
    setOpenDialog(false)
  }

  const handleOpenDialog = (lessonId) => {
    setOpenDialog(true)
    setLessonToDelete(lessonId)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleRowClick = (lessonId) => {
    navigate(`/lesson/${lessonId}`)
  }

  return (
    <Box sx={{ p: 1, bgcolor: 'background.default', display: 'grid', gap: 1 }}>
      <Paper elevation={6}>
        <Box padding={1}>
          <Typography variant='h4' component='h2' marginLeft={0}>
            <Box component='span' fontWeight='bold' marginLeft={1}>
              Workouts
            </Box>
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lessons
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lesson) => (
                  <TableRow
                    key={lesson.id}
                    hover
                    onClick={() => handleRowClick(lesson.id)}
                    style={{ cursor: 'pointer' }}>
                    <TableCell>{lesson.title}</TableCell>
                    <TableCell>{formatDate(lesson.createdAt)}</TableCell>
                    {true && ( // #MRR -> Was using isAppAdmin to hide
                      <TableCell>
                        <Box display='flex' gap={1}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            onClick={(event) => {
                              event.stopPropagation()
                              navigate(`/lesson/${lesson.id}`)
                            }}>
                            View
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            onClick={(event) => {
                              event.stopPropagation()
                              navigate(`/lesson-edit/${lesson.id}`)
                            }}>
                            Edit
                          </Button>
                          <Button
                            variant='outlined'
                            color='error'
                            size='medium'
                            onClick={(event) => {
                              event.stopPropagation()
                              handleOpenDialog(lesson.id)
                            }}>
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={lessons.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => navigate('/workout')}>
            Add Workout
          </Button>
        </Box>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'>
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure you want to delete this lesson?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDelete} color='error' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default LessonsTable
